import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    "page-content-top-after": _withCtx(() => [
      _createVNode(_component_tm_tabs, {
        modelValue: _ctx.currentRoute,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRoute) = $event)),
        tabs: _ctx.tabs,
        "use-router": "",
        class: "mt-4"
      }, {
        label: _withCtx(({ tab }) => [
          _createElementVNode("span", {
            class: _normalizeClass(tab.name === 'API v1 (legacy)' && _ctx.currentRoute !== tab.name && 'light--text' || '')
          }, _toDisplayString(tab.name), 3)
        ]),
        _: 1
      }, 8, ["modelValue", "tabs"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 3
  }))
}