
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  name: 'ServicesApi',
  components: {
    PageContent,
    TmTabs,
  },
  setup() {
    const currentRoute = ref('')
    const tabs = [
      { name: 'API v2 keys', to: { name: 'base.services.api.keys' } },
      { name: 'API v2 settings', to: { name: 'base.services.api.settings' } },
      { name: 'Integrations', to: { name: 'base.services.api.integrations' } },
      // { name: 'Verify', to: { name: 'base.services.api.verify' } },
      { name: 'API v1 (legacy)', to: { name: 'base.services.api.legacy' } },
    ]

    return {
      currentRoute,
      tabs,
    }
  },
})
